<template>
  <hr style="color: #3e989f" />

  <section class="banner-one">
    <div class="container">
      <div class="row text-data">
        <div class="text-color">
          <p class="div-text-align">Dear doc,</p>
          <p class="div-text-align">
            I invite you to try out RxIx App. It helps you to manage and grow
            your Practice. Get a glimpse of some of its features.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="banner-two">
    <div class="container text-center">
      <div
        class="row img-text"
      >
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/web.png" />
          </div>
          <p class="mt-2 text-color">
            Join disorder-specific patient support groups. Get them to know you.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/meet.png" />
          </div>
          <p class="mt-3 text-color">
            Seek inputs for a case from other RxIx doctors.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/phone.png" width="35%" />
          </div>
          <p class="mt-2 text-color">
            Store case sheets. Click and upload handwritten prescriptions.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/msg.png" />
          </div>
          <p class="mt-3 text-color">
            Access patient's case sheets created by other RxIx doctors.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/meet.png" />
          </div>
          <p class="mt-3 text-color">
            Use illustrations to easily explain to patients.
          </p>
        </div>
        <div class="col img-div">
          <div class="img mt-2">
            <img class="icon-img" src="~@/assets/img/one_one.png" />
          </div>
          <p class="mt-2 text-color">
            Get patients referrals based on your location and practice areas.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="banner-three-text">
    <div class="container">
      <div class="row text-data">
        <div class="text-color">
          <p class="div-text-align">
            Completing the consultation, the app also drives reliable contextual
            healthcare information to patients - no more Googling or reading
            unverified into from social media platforms.
          </p>
        </div>
        <div class="text-color">
          <p class="div-text-align">
            If you have any queries, please call Rameshwar on 995 296 9922.
          </p>
        </div>
        <div class="text-color mt-2">
          <button
            type="button"
            class="btn btn-color text-white"
            style="border-radius: 10px"
          >
            Explore the app
          </button>
        </div>
        <div class="text-color mt-2">
          <p class="div-text-align">Warm regards,</p>
        </div>
      </div>
    </div>
  </section>
</template>
<style>
.container.text-center{
  width: 60%;
  margin: auto;
}
.row.text-data{
  width: 67%;
  margin: auto;
}
.row.img-text{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}
@media screen and (max-width:580px) {
  .row.text-data{
    width: 100%; margin: auto
  }
}

img.icon-img {
  width: 37%;
}
.col.img-div {
  border: 2px solid #67c0c5;
  border-radius: 20px;
  margin: 15px;
  width: 65%;
}

@media screen and (max-width:580px){
  .col.img-div {
  border: 2px solid #67c0c5;
  border-radius: 20px;
  margin: 15px;
  width: 100%;
}
}
p.mt-2.text-color{
  font-size: 15px;
  text-align: left;
}
p.mt-3.text-color{
  font-size: 15px;
  text-align: left;
}
p.div-text-align{
  margin-bottom: 0.2rem;
}
.rxix-menu li.nav-item a.nav-link {
  color: #00979e;
}
@media screen and (max-width:580px) {
section.banner-three-text{
  margin-bottom: 80px;
}
}

</style>